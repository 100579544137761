import React from "react"
import Layout from "../layouts/index"

export default () => (
  <Layout>
    <div className="content-wrap">
      <div className="about-info">
        <h1>全球领先的服务管理解决方案提供商</h1>
        <div className="awards-group">
          <div className="award-info">
            <div className="award-pic"></div>
            <p>
              Forrester
              <br />
              2019
              <br />
              行业领袖
            </p>
          </div>
          <div className="award-info">
            <div className="award-pic"></div>
            <p>
              深圳市智慧杆
              <br />
              产业促进会
              <br />
              理事单位
            </p>
          </div>
        </div>
        <p>
          ServiceAI是一家基于SaaS的软件公司，它的愿景是变革服务管理。通过人工智能机器学习和自然语言处理等方面的技术积累和突破，我们正在改变企业与员工、企业与客户的互动方式。
        </p>
        <p>
          ServiceAI使用人工智能技术来助力员工和客户提高生产力和问题响应效率。在提升客户体验的同时，我们智能地将任务和流程自动化，以释放员工效能。
        </p>
        <p>
          服务管理流程可以实现端到端的整体优化。ServiceAI专注于无人工干预的自动化服务流程处理，可以支持IVR、微信、钉钉等移动端IM应用。ServiceAI还可以与企业内部系统整合打通，例如ERP、CRM、OA或其他定制化企业内部系统。
        </p>
        <p>
          ServiceAI与合作伙伴一起联手打造卓越的生产效率。服务管理的“零接触”方法将允许企业专注于复杂的任务，从而提高员工的工作效率。您的IT分析师可以专注于更具战略性的任务，减少重复工作，节约成本，提高效率。
        </p>
        <p>
          我们不仅变革了服务管理，而且实现了简单易部署的方案——客户并不需要在部署过程中拥有数据科学家。ServiceAI提供全流程图形界面工具，帮助客户自助部署，并随时根据报表情况进行调整。在此过程中，您不需要雇佣专业的人工智能专家和算法技术人员。
        </p>
        <p>
          ServiceAI由一个富有才华和经验的团队领导，他们在软件和技术领域拥有超过25年的经验。他们始终关注创新和更好的满足客户需求。
        </p>
      </div>
    </div>
    <div className="members">
      <div className="content-wrap">
        <h2>我们的团队</h2>
        <div className="member-cards">
          <div className="member-card ceo">
            <h3>李业 Yip</h3>
            <h4>CEO</h4>
            <p>
              具有超过26年软件领域的经验，创立ServiceAI之前，曾担任位于北京的NUAGE
              SYSTEMS的CEO。他曾先后担任过香港 CSL (TELSTRA HK) 的执行副总裁和
              ORACLE的副总裁兼专业服务业务部的副总裁及BEA SYSTEMS的全球副总裁。
            </p>
          </div>
          <div className="member-card market">
            <h3>钟东 Alice</h3>
            <h4>中国市场营销</h4>
            <p>
              他在IT领域具有19年的工作经历。加入ServiceAI之前，工作于神州数码，曾任神州数码系统产品服务事业部总经理，负责企业级IT产品的售后服务营销体系的搭建。曾任神州数码数据服务事业部副总经理，负责大数据分析类产品的销售及市场生态搭建，MSP招募。
            </p>
          </div>
          <div className="member-card tech">
            <h3>崔滔 Tomi</h3>
            <h4>中国研发中心</h4>
            <p>
              他在电信和IT领域具有24年的工作经历。加入ServiceAI中国之前，作为ORACLE高级技术总监，负责电信事业部北京研发中心的日常管理，包括研发，问题响应，产品发布及其新产品预研。曾担任BEA
              SYSTEMS的高级技术经理和LUCENT TECHNOLOGIES (中国)
              贝尔实验室的技术经理。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="contact-section">
      <div className="contact-info-group">
        <div className="contact-info">
          <h2>联系方式</h2>
          <p>联系电话</p>
          <a href="callto:62138000">62138000</a>
          <p>电子邮箱</p>
          <a href="mailto:sales@serviceai.cn">sales@serviceai.cn</a>
          <p>公司地址</p>
          <h5>上海市徐汇区武康路333号霞飞别墅5栋</h5>
          <p>传真</p>
          <h5>62137000</h5>
        </div>
      </div>
      <div className="contact-map"></div>
    </div>
  </Layout>
)
